<template>
  <div class='icon-info'>
    <svg xmlns="http://www.w3.org/2000/svg" width="46.221" height="46.221" viewBox="0 0 46.221 46.221">
      <path id="Path_38111" data-name="Path 38111" d="M23.109,0A23.11,23.11,0,1,0,46.221,23.109,23.111,23.111,0,0,0,23.109,0ZM27.92,35.816q-1.784.7-2.847,1.072a7.51,7.51,0,0,1-2.469.37A4.921,4.921,0,0,1,19.245,36.2a3.4,3.4,0,0,1-1.2-2.674,9.715,9.715,0,0,1,.088-1.289c.061-.438.157-.931.288-1.485L19.915,25.5c.131-.5.245-.984.335-1.43a6.341,6.341,0,0,0,.133-1.238,1.907,1.907,0,0,0-.415-1.4,2.356,2.356,0,0,0-1.591-.393,4.174,4.174,0,0,0-1.184.176c-.4.123-.749.235-1.035.344l.393-1.62q1.461-.6,2.8-1.019a8.265,8.265,0,0,1,2.524-.427,4.829,4.829,0,0,1,3.31,1.037,3.445,3.445,0,0,1,1.162,2.692q0,.343-.08,1.207a8.077,8.077,0,0,1-.3,1.587l-1.481,5.243c-.121.421-.229.9-.327,1.44a7.614,7.614,0,0,0-.143,1.225,1.759,1.759,0,0,0,.468,1.424,2.617,2.617,0,0,0,1.618.38,4.693,4.693,0,0,0,1.225-.19,6.965,6.965,0,0,0,.99-.333Zm-.262-21.282a3.535,3.535,0,0,1-2.494.963,3.572,3.572,0,0,1-2.5-.963A3.073,3.073,0,0,1,21.617,12.2a3.1,3.1,0,0,1,1.043-2.34,3.546,3.546,0,0,1,2.5-.972,3.506,3.506,0,0,1,2.494.972,3.152,3.152,0,0,1,0,4.674Z" :fill="color"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconInfo',
  props: {
    color: {
      type: String,
      required: false,
      default: '#fff',
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
</style>
