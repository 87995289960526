<template>
  <div id='cart-mergeable-info'>
    <div class="content-wrapper">
      <div class="icon-row">
        <icon-info />
      </div>
      <div class="text-row">
        {{ $t(contentTextKey) }}
      </div>
      <div class="button-row">
        <span ref="buttonRef" class="button" :style="`min-width: ${btnMinWidth}px;`" @click="handleBtnClick">
          <span v-if="!isBtnLoading" class="text">{{ $t(btnTextKey) }}</span>
          <span v-else class="loader"><font-awesome-icon :icon="['fa', 'spinner']" /></span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import IconInfo from '@/components/shared/elements/icons/IconInfo';

export default {
  name: 'CartMergeableInfo',
  props: {
    contentTextKey: {
      type: String,
      required: false,
      default: 'pages.cart.mergeable.text',
    },
    btnTextKey: {
      type: String,
      required: false,
      default: 'pages.cart.mergeable.button.text',
    },
    isBtnLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    IconInfo,
  },
  data: () => ({
    btnMinWidth: 0,
  }),
  created() {
    this.$nextTick(() => {
      this.btnMinWidth = document.querySelector('span.button').clientWidth;
    });
  },
  computed: {},
  methods: {
    handleBtnClick() {
      this.$emit('btnClicked');
    },
  },
};
</script>

<style lang='scss' scoped>
@keyframes loaderAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.content-wrapper {
  background-color: $globalInfoColor;
  color: $white;
  border-radius: $globalBorderRadius;
  text-align: center;
  padding: 16px 28px 18px;
}

.icon-row {
  margin: 0 0 13px;
}

.text-row {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  margin: 0 0 13px;
}

.button {
  display: inline-block;
  border-radius: $globalBorderRadius;
  background-color: $white;
  color: $globalInfoColor;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  line-height: 35px;
  padding: 0 35px;
  cursor: pointer;
  text-transform: uppercase;
  box-shadow: 0 5px 15px rgba(#1ED92A, 0.35);
  transition: $transitionBase;
}

.loader {
  display: inline-block;
  animation: loaderAnimation 1.6s infinite;
}
</style>
